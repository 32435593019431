<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<div
  *ngIf="customizedSuccessMessage.length !== 0"
  class="alert alert-success alert-dismissible fade show ms-4 me-4 p-2 mt-2"
  role="alert"
>
  <button
    type="button"
    class="btn-close"
    (click)="close()"
    data-bs-dismiss="alert"
    aria-label="close"
  ></button>
  <strong class="ps-2" *ngFor="let i of customizedSuccessMessage"
    >{{ i }}<br
  /></strong>
</div>
<div *ngIf="errorMessageHeader !== undefined">
  <div
    class="alert alert-danger alert-dismissible ms-4 me-4 p-2 mt-2 ng-star-inserted"
    style="
      margin-bottom: -7px;
      --bs-alert-border-radius: 0.375rem 0.375rem 0 0;
      border-bottom: 1px solid;
      --bs-alert-bg: #ebabb1;
    "
  >
    <button
      type="button"
      class="btn-close"
      (click)="close()"
      data-bs-dismiss="alert"
      aria-label="close"
    ></button>
    <h3 style="margin-bottom: 0px !important">{{ errorMessageHeader }}</h3>
  </div>
  <div
    class="alert alert-danger alert-dismissible ms-4 me-4 p-2 mt-2"
    style="border-top: none; --bs-alert-border-radius: 0 0 0.375rem 0.375rem"
  >
    <strong class="ps-2" *ngFor="let i of customizederrorMessage"
      >{{ i }}<br
    /></strong>
  </div>
</div>
<div
  *ngIf="
    customizederrorMessage.length !== 0 && errorMessageHeader === undefined
  "
  class="alert alert-danger alert-dismissible ms-4 me-4 p-2 mt-2"
>
  <button
    type="button"
    class="btn-close"
    (click)="close()"
    data-bs-dismiss="alert"
    aria-label="close"
  ></button>
  <strong class="ps-2" *ngFor="let i of customizederrorMessage"
    >{{ i }}<br
  /></strong>
</div>

<div
  class="d-flex justify-content-between flex-wrap footer align-items-center me-2 ms-2"
>
  <div class="d-flex justify-content-start">
    <button class="mt-3 me-2 action-btn">
      <fa-icon
        [icon]="faFilter"
        title="Filter Grid"
        class="d-flex justify-content-center"
      ></fa-icon>
    </button>
    <button class="mt-3 me-2 action-btn" *ngIf="eventId !== '-1'">
      <fa-icon
        [icon]="faColumns"
        title="Save Columns"
        class="d-flex justify-content-center"
      ></fa-icon>
    </button>
  </div>

  <div class="d-flex justify-content-end">
    <button class="mt-3 me-2 action-btn" (click)="viewTrails()">
      <fa-icon
        [icon]="faEye"
        title="View Single Order"
        class="d-flex justify-content-center"
      ></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
      (click)="createTrails()"
    >
      <fa-icon [icon]="faPlus" title="Create New Order"></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      (click)="createTrails()"
    >
      <fa-icon [icon]="faPlus" title="Create New Order"></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
      (click)="appendTrails()"
    >
      <fa-icon [icon]="faList" title="Append Multiple Orders"></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      (click)="appendTrails()"
    >
      <fa-icon [icon]="faList" title="Append Multiple Orders"></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false'  || eventId === '-1' ) &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
      (click)="cancelTrails()"
    >
      <fa-icon [icon]="faTrash" title="Cancel Order(s)"></fa-icon>
    </button>

    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false'  || eventId === '-1' ) &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      (click)="cancelTrails()"
    >
      <fa-icon [icon]="faTrash" title="Cancel Order(s)"></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false'  || eventId === '-1' ) &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
      (click)="openReviewShipDate()"
    >
      <fa-icon
        [icon]="faCheckSquareO"
        title="Review Supplier Ship Date"
      ></fa-icon>
    </button>

    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false'  || eventId === '-1' ) &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon
        [icon]="faCheckSquareO"
        title="Review Supplier Ship Date"
      ></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false'  || eventId === '-1' ) &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
      (click)="openAcceptShipDateDialog()"
    >
      <fa-icon [icon]="faThumbsUp" title="Accept Supplier Ship Date"></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false'  || eventId === '-1' ) &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon [icon]="faThumbsUp" title="Accept Supplier Ship Date"></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false'  || eventId === '-1' ) &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
      (click)="openRejectShipDateDialog()"
    >
      <fa-icon
        [icon]="faThumbsDown"
        title="Reject Supplier Ship Date"
      ></fa-icon>
    </button>

    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false'  || eventId === '-1' ) &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon
        [icon]="faThumbsDown"
        title="Reject Supplier Ship Date"
      ></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
      (click)="namcCommentsValidation()"
      (keydown)="namcCommentsValidation()"
    >
      <fa-icon [icon]="faComment" title="NAMC Comments"></fa-icon>
    </button>

    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon [icon]="faComment" title="NAMC Comments"></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      title="Send to SAP"
      style="width: 72px"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        eventDetailsFlags.showEdit === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
      (click)="sendToSap()"
    >
      <fa-icon [icon]="faEnvelope" class="me-1"></fa-icon>SAP
    </button>
    <button
      class="mt-3 me-2 action-btn"
      style="width: 72px"
      title="Enable for SAP"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        eventDetailsFlags.showEdit === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
      (click)="enableSap()"
    >
      <fa-icon [icon]="faThumbsUp" class="me-1"></fa-icon>SAP
    </button>
    <button
      class="mt-3 me-2 action-btn"
      title="Disable for SAP"
      style="width: 72px"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        eventDetailsFlags.showEdit === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
      (click)="disableSap()"
    >
      <fa-icon [icon]="faThumbsDown" class="me-1"></fa-icon>SAP
    </button>
    <button class="mt-3 me-2 action-btn">
      <fa-icon
        [icon]="faShareFromSquare"
        title="Export Orders"
        (click)="exportOrders()"
        (keydown)="exportOrders()"
      ></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin'
      "
    >
      <fa-icon
        [icon]="faEnvelope"
        title="Send Supplier Notification"
        onKeyPress="openSupplierNotification()"
        (click)="openSupplierNotification()"
      ></fa-icon>
    </button>

    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        this.currentRole === 'IS Admin' ||
        this.currentRole === 'QE User' ||
        this.currentRole === 'PE User' ||
        this.currentRole === 'Shop User' ||
        this.currentRole === 'PC User'
      "
      disabled
    >
      <fa-icon [icon]="faEnvelope" title="Send Supplier Notification"></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
    >
      <fa-icon
        [icon]="faCalendar"
        title="Supplier Ship Date"
        onkeypress=""
        (click)="openShipChangeDialog()"
      ></fa-icon>
    </button>

    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'false' ||
          eventDetailsFlags.isEventDeleted === 'false') &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon
        [icon]="faCalendar"
        title="Supplier Ship Datee"
        onkeypress=""
        (click)="openShipChangeDialog()"
      ></fa-icon>
    </button>
    <button
      class="mt-3 me-2 action-btn"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false'
      "
    >
      <fa-icon
        [icon]="faWeibo"
        title="Supplier Ship Date Change Request"
        onkeypress=""
        (click)="openShipRequestDialog()"
      ></fa-icon>
    </button>
  </div>
</div>
