import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AcceptOrdersDialogComponent } from 'src/app/core/dialogs/event-and-orders/accept-orders-dialog/accept-orders-dialog.component';
import { ConfirmSelectedOrdersComponent } from 'src/app/core/dialogs/event-and-orders/confirm-selected-orders/confirm-selected-orders.component';
import { ShipChangeRequestComponent } from 'src/app/core/dialogs/event-and-orders/ship-change-request/ship-change-request.component';
import { ShipDateNamcComponent } from 'src/app/core/dialogs/event-and-orders/ship-date-namc/ship-date-namc.component';
import { SupplierMailComponent } from 'src/app/core/dialogs/event-and-orders/supplier-mail/supplier-mail.component';
import { SupplierShipDateComponent } from 'src/app/core/dialogs/event-and-orders/supplier-ship-date/supplier-ship-date.component';
import { EditSingleOrderComponent } from 'src/app/core/grid-renderer/refresh/edit-single-order/edit-single-order.component';
import { RefreshComponent } from 'src/app/core/grid-renderer/refresh/refresh.component';
import { NamcCommentsActionList } from 'src/app/core/model/comments.model';
import {
  AttributeDetails,
  EciApiResponse,
  ExcelHeader,
  TrialOrderDetails,
} from 'src/app/core/model/trial-order.model';
import { User } from 'src/app/core/model/user.model';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { HomeService } from 'src/app/services/home.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-display-trial-orders',
  templateUrl: './display-trial-orders.component.html',
  styleUrls: ['./display-trial-orders.component.scss'],
})
export class DisplayTrialOrdersComponent {
  holdOriginalArray!: string;
  columnDefs: ColDef[] = [];
  displayRowData: TrialOrderDetails[] = [];
  selectedRows: TrialOrderDetails[] = [];
  namcCode = '';
  currentRole = '';
  user: User | null = null;
  allEvents = true;
  eventId = '';
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  eventDetailsFlags: AttributeDetails = {
    trialEventName: '',
    displayPOTrackingNumber: '',
    showEdit: '',
    isAllEvents: '',
    isEventDeleted: '',
  };
  rowSelection: 'single' | 'multiple' | undefined = 'multiple';
  limit = 10;
  rowCount = 0;
  selectedOrder: TrialOrderDetails[] = [];
  dummy: boolean | undefined;
  flag: boolean | undefined;
  unChangeMessage = 'From Trial Order, do NOT change this field.';
  supShipDateMessage =
    'MM/DD/YYYY format, Date parts are due at NAMC. Do NOT change this field.';
  supPickupDateMessage =
    'MM/DD/YYYY format, Date parts are planned to be picked up at supplier. Do NOT change this field.';
  labelRequestMessage = 'From Label Request, do NOT change this field.';
  bufferType = 'application/octet-stream';
  orderDetails = '';

  constructor(
    private readonly userService: UserService,
    private readonly trialOrderService: TrialOrdersService,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly eciService: TrialOrdersService,
    private readonly orderService: TrialOrdersService,
    private readonly quickLinkService: HomeService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const result = navigation?.extras.state?.['result'];
    if (result) {
      this.successMessage = result;
      setTimeout(() => {
        this.successMessage = undefined;
      }, 5000);
    }
  }

  ngOnInit(): void {
    this.eventId = String(sessionStorage.getItem('eventId'));
    this.orderDetails = sessionStorage.getItem('quickLinkOrderDetails') ?? '';
    this.namcButton();
    if (this.eventId === '-1') {
      this.getColDefs();
      this.getQuickLinkOrderDetails(this.orderDetails);
    } else {
      this.setEventDetails();
      this.getGridData();
    }
    this.userService.userDetails.subscribe((user) => {
      this.user = user;
      if (user) {
        this.loadRoleDetails();
      }
    });
    if (history.state?.errorMessage || history.state?.successMessage) {
      this.showMessage();
    }
  }

  showMessage() {
    this.errorMessage = history.state?.errorMessage;
    this.successMessage = history.state?.successMessage;
    this.settingTimeOut();
  }

  namcButton() {
    this.dummy = Boolean(history.state?.dummy);
    if (this.dummy) {
      if (history.state?.err) {
        this.errorMessage = String(history.state?.err);
      } else {
        this.successMessage = String(history.state?.res.message);
      }
      setTimeout(() => {
        this.errorMessage = undefined;
        this.successMessage = undefined;
      }, 5000);
    } else if (history.state?.cancel) {
      if (!history.state?.res.cancelFlag) {
        this.errorMessage = history.state?.res.status;
      } else {
        this.successMessage = history.state?.res.status;
      }
      setTimeout(() => {
        this.errorMessage = undefined;
        this.successMessage = undefined;
      }, 50000);
    }
  }
  loadRoleDetails() {
    this.userService.selectedRoles?.subscribe((selectedRoles) => {
      const role = this.user?.roles.find(
        (value) => value.namcCode === selectedRoles?.namc
      );
      this.currentRole = role?.roleDescription ?? '';
      this.namcCode = role?.namcCode ?? '';
    });
  }

  setEventDetails() {
    this.ngxUiLoaderService.start('setupevent');
    this.trialOrderService?.getEventDetails(this.eventId)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('setupevent');
        this.eventDetailsFlags = result;
        this.getColDefs();
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('setupevent');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  getColDefs() {
    this.columnDefs = [
      {
        floatingFilterComponent: RefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        checkboxSelection: true,
        minWidth: 60,
        maxWidth: 60,
        pinned: 'left',
      },
      {
        headerName: 'ORDER STATUS',
        field: 'status',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (params: { value: string }) => {
          return `<span class="status-holder 
            ${params.value}
            "> 
            ${params.value.replace('_', ' ')}
            </span>`;
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 195,
        minWidth: 195,
      },
      {
        headerName: 'ORDER TRACKING NO',
        field: 'orderNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 160,
        minWidth: 160,
      },
      {
        headerName: 'ORDER TYPE',
        field: 'orderType',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 130,
        minWidth: 130,
      },
      {
        headerName: 'PO Tracking No',
        field: 'poTrackingNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value.length > 12) {
            return { color: 'red' };
          } else {
            return { color: 'black' };
          }
        },
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 160,
        minWidth: 160,
      },
      {
        headerName: 'SAP Status',
        field: 'sapStatus',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (params: { value: string }) => {
          const splits = params.value.split(';');
          return `<span class="fa sap-status ${splits[0]
            .replace(' ', '')
            .toLowerCase()}" title="${splits[1]}"> 
            <span class="sapStatusval">
            ${splits[0]}
            </span>
            </span>`;
        },
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 130,
        minWidth: 130,
      },
      {
        headerName: 'EVENT NAME',
        field: 'eventName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (params: { value: string }) => {
          return this.renderTitle(params);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 210,
        minWidth: 210,
      },
      {
        headerName: 'PROJECT',
        field: 'projectCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 90,
        minWidth: 90,
      },
      {
        headerName: 'PSMS ROUTING',
        field: 'psmsRouting',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (params: { value: string }) => {
          return this.renderEditTitle(params);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value.length > 5) {
            return {
              color: 'red',
            };
          } else {
            return {
              color: 'black',
            };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        headerName: 'SPECIALIST NAME',
        field: 'specialistName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: any) => {
          this.handleCellValueChanged(param);
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 210,
        minWidth: 210,
      },
      {
        headerName: 'SUPPLIER CODE',
        field: 'supplierCd',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 90,
        minWidth: 90,
      },
      {
        headerName: 'KANBAN NUMBER',
        field: 'kanbanNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value.length > 4) {
            return {
              color: 'red',
            };
          } else {
            return {
              color: 'black',
            };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 150,
        minWidth: 150,
      },
      {
        headerName: 'PART NUMBER',
        field: 'partNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 170,
        minWidth: 150,
      },
      {
        headerName: 'PART NAME',
        field: 'partName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value.length > 50 || params.value.length === 0) {
            return {
              color: 'red',
            };
          } else {
            return {
              color: 'black',
            };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 250,
        maxWidth: 250,
      },
      {
        headerName: 'REVISION',
        field: 'revisionDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 160,
        minWidth: 160,
      },
      {
        headerName: 'NAMC DUE DATE',
        field: 'orderDueDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
        maxWidth: 160,
      },
      {
        headerName: 'SUPPLIER PICKUP DATE',
        field: 'supplierPickupDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
        maxWidth: 160,
      },
      {
        headerName: 'SUPPLIER SHIP DATE',
        field: 'promiseDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'TOTAL QTY',
        field: 'totalQty',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 90,
        maxWidth: 90,
      },
      {
        headerName: 'SUPPLIER NAME',
        field: 'supplierName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 250,
        maxWidth: 250,
      },
      {
        headerName: 'NAMC COMMENT',
        field: 'namcComment',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value !== null) {
            if (params.value.length > 1000) {
              return {
                color: 'red',
              };
            } else {
              return {
                color: 'black',
              };
            }
          }
          return { color: 'black' };
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 250,
        maxWidth: 250,
      },
      {
        headerName: 'SUPPLIER COMMENT',
        field: 'supplierComment',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 250,
        maxWidth: 250,
      },
      {
        headerName: 'STORE ADDRESS',
        field: 'storeAddress',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value.length > 10) {
            return {
              color: 'red',
            };
          } else {
            return {
              color: 'black',
            };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
        maxWidth: 150,
      },
      {
        headerName: 'LINE SIDE ADDRESS',
        field: 'lineSideAddress',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value.length > 10) {
            return {
              color: 'red',
            };
          } else {
            return {
              color: 'black',
            };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
        maxWidth: 150,
      },
      {
        headerName: 'DOCK CODE',
        field: 'dockCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: 'PALLET CODE',
        field: 'palletCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: 'MANIFEST NUMBER',
        field: 'manifestNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'RECEIVER NUMBER',
        field: 'orderReceiverNbr',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'NA PIPE STATUS',
        field: 'naPipeStatus',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        hide:
          this.eventDetailsFlags.displayPOTrackingNumber === ''
            ? false
            : this.eventDetailsFlags.displayPOTrackingNumber !== 'false',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: 'ECI NUMBER',
        field: 'eciNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'ECI REVISION',
        field: 'eciRevision',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: 'SPECIALIST CODE',
        field: 'specialistCd',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: 'SHIPPING DATE',
        field: 'shippingDateTime',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderConditionalEdit(param);
        },
        cellStyle: (params) => {
          const dateRegex =
            /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
          if (params.value.length === 0) {
            return {
              color: 'black',
            };
          } else if (!dateRegex.test(params.value)) {
            return {
              color: 'red',
            };
          }
          return {
            color: 'black',
          };
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'TRAILER NUMBER',
        field: 'trailerNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderConditionalEdit(param);
        },
        cellStyle: (params) => {
          if (params.value.length > 30) {
            return {
              color: 'red',
            };
          } else {
            return {
              color: 'black',
            };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'SHIPPING CARRIER',
        field: 'shippingCarrier',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderConditionalEdit(param);
        },
        cellStyle: (params) => {
          if (params.value.length > 30) {
            return {
              color: 'red',
            };
          } else {
            return {
              color: 'black',
            };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'TRACKING/AIRLBILL NO',
        field: 'trackingAirbillNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderConditionalEdit(param);
        },
        cellStyle: (params) => {
          if (params.value.length > 30) {
            return {
              color: 'red',
            };
          } else {
            return {
              color: 'black',
            };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'LIFE CYCLE CODE',
        field: 'lifeCycleCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        cellStyle: (params) => {
          if (params.value != null) {
            if (params.value.length > 1) {
              return {
                color: 'red',
              };
            } else {
              return {
                color: 'black',
              };
            }
          }
          return { color: 'black' };
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: 'WORK ORDER NUMBER',
        field: 'workOrderNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        cellStyle: (params) => {
          if (params.value != null) {
            if (params.value.length > 17) {
              return {
                color: 'red',
              };
            } else {
              return {
                color: 'black',
              };
            }
          }
          return { color: 'black' };
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 180,
        maxWidth: 180,
        hide: this.namcCode !== '26MTM',
      },
      {
        headerName: 'SUPPLIER SHIP DATE CHANGE REQUEST',
        field: 'requestForSuppShipDtChng',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 300,
        maxWidth: 1000,
      },
      {
        headerName: 'SUPPLIER SHIP DATE CHANGE COMMENT',
        field: 'requestForSuppShipcmmntChng',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 300,
        maxWidth: 1000,
      },
      {
        headerName: 'OWK LABEL LINE1',
        field: 'owkLabelLine1',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 210,
        maxWidth: 210,
      },
      {
        headerName: 'OWK LABEL LINE2',
        field: 'owkLabelLine2',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 210,
        maxWidth: 210,
      },
      {
        headerName: 'SCAN COMMENT',
        field: 'scanComment',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 210,
        maxWidth: 210,
      },
      {
        headerName: 'SEND TO SAP FLAG',
        field: 'sendToSapFlag',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 200,
        maxWidth: 350,
      },
      {
        headerName: 'PO LINE NUMBER',
        field: 'poLineNo',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
        maxWidth: 160,
      },
      {
        headerName: 'ARIBA PR NO',
        field: 'aribaPrNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
        maxWidth: 160,
      },
      {
        headerName: 'ARIBA ORDER STATUS',
        field: 'aribaOrderStatus',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 200,
        maxWidth: 350,
      },
      {
        headerName: 'ARIBA ORDER COMMENT',
        field: 'aribaOrderComment',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 200,
        maxWidth: 350,
      },
      {
        headerName: 'SAP SUBMISSION COMMENT',
        field: 'sapStatus',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        hide: true,
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 200,
        maxWidth: 350,
      },
      {
        headerName: 'ACTION',
        floatingFilter: false,
        cellRenderer: EditSingleOrderComponent,
        cellRendererParams: {
          buttonConfiguration: this.eventDetailsFlags,
          eventId: this.eventId,
        },
        minWidth: 70,
        maxWidth: 70,
        pinned: 'right',
      },
    ];
  }

  renderTitle(params: { value: string }) {
    if (params.value && params.value !== '') {
      const displayValue =
        params.value.length > 25
          ? params.value.substring(0, 25) + '...'
          : params.value;
      return `<span title="${params.value}">${displayValue}</span>`;
    }
    return params.value;
  }

  renderEditTitle(params: { value: string }) {
    const { isAllEvents } = this.eventDetailsFlags;
    if (isAllEvents === 'false') {
      const displayValue =
        params.value.length > 25
          ? params.value.substring(0, 25) + '...'
          : params.value;
      return `<div><span title="${params.value}">${displayValue}</span><span><i class="material-icons small-icon">edit</i></span></div>`;
    }
    return this.renderTitle(params);
  }
  onRowSelectionChanged(selectedRows: TrialOrderDetails[]) {
    this.selectedRows = selectedRows;
  }
  renderConditionalEdit(params: any) {
    if (
      this.eventDetailsFlags.isAllEvents === 'false' &&
      params.data.eciNumber.length > 0
    ) {
      const displayValue =
        params.value.length > 25
          ? params.value.substring(0, 25) + '...'
          : params.value;
      return `<div><span title="${params.value}">${displayValue}</span><span><i class="material-icons small-icon">edit</i></span></div>`;
    }
    return this.renderTitle(params);
  }

  onPaginationChanged(_event: number) {
    this.limit = _event;
    if (this.eventId !== '-1') {
      this.getGridData();
    }
  }

  getGridData() {
    this.ngxUiLoaderService.start('griddata');
    this.trialOrderService.getGridData(this.eventId, 1, this.limit)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('griddata');
        this.rowCount = result.totalRow;
        this.displayRowData = result.trialOrderData as TrialOrderDetails[];
        this.holdOriginalArray = JSON.stringify(
          result.trialOrderData as TrialOrderDetails[]
        );
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('griddata');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  onSelectOrder(event: TrialOrderDetails[]) {
    this.selectedOrder = event;
  }

  onNamcCommentEmit(event: NamcCommentsActionList) {
    if (event.message === '') {
      if (this.eventId !== '-1') {
        this.setEventDetails();
        this.getGridData();
        return;
      } else {
        this.getColDefs();
        this.getQuickLinkOrderDetails(this.orderDetails);
        return;
      }
    }
    if (event.success === true) {
      this.setEventDetails();
      this.getGridData();
      this.successMessage = event.message;
      this.settingTimeOut();
    } else {
      this.errorMessage = event.message;
      this.settingTimeOut();
    }
  }

  cancel() {
    window.history.back();
  }
  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }

  openAcceptDialog(): void {
    const dialogRef = this.dialog.open(AcceptOrdersDialogComponent, {
      width: '400px',
      data: {
        headerTitle: 'Accept',
      },
    });

    dialogRef.afterClosed().subscribe((_result) => {});
  }
  openRejectDialog(): void {
    const dialogRef = this.dialog.open(AcceptOrdersDialogComponent, {
      width: '400px',
      data: {
        headerTitle: 'Reject',
      },
    });
    dialogRef.afterClosed().subscribe((_result) => {});
  }
 
  openNamcReview() {
    this.router.navigate(['event-order/trial-orders/confirmOrderdo']);
  }
  openConfirmDialog() {
    const dialogRef = this.dialog.open(ConfirmSelectedOrdersComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((_result) => {});
  }


  openSupplierMail() {
    const dialogRef = this.dialog.open(SupplierMailComponent, {
      width: '50%',
      height: '83%',
    });
    dialogRef.afterClosed().subscribe((_result) => {});
  }

  openShipRequestDialog() {
    const dialogRef = this.dialog.open(ShipChangeRequestComponent, {
      width: '400px',
    });
    dialogRef.afterClosed().subscribe((_result) => {});
  }
  openShipDialog() {
    const dialogRef = this.dialog.open(ShipDateNamcComponent, {
      width: '400px',
    });
    dialogRef.afterClosed().subscribe((_result) => {});
  }
  openShipChangeDialog() {
    const dialogRef = this.dialog.open(SupplierShipDateComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((_result) => {});
  }

  getQuickLinkOrderDetails(oderDetails: string) {
    this.ngxUiLoaderService.start('quicklinksorders');
    this.quickLinkService.getQuickLinkOrderDetails(oderDetails)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('quicklinksorders');
        this.displayRowData = result.trialOrderData as TrialOrderDetails[];
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('quicklinksorders');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  handleCellValueChanged(params: any): void {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        params.node.setSelected(false);
      }
    };

    window.addEventListener('keydown', listener);
  }

  onSave() {
    if (
      this.displayRowData.length > 0 &&
      this.hasChanges(
        JSON.parse(this.holdOriginalArray) as TrialOrderDetails[],
        this.displayRowData
      )
    ) {
      const obj = this.compareOrders(
        JSON.parse(this.holdOriginalArray) as TrialOrderDetails[],
        this.displayRowData
      );
      this.saveMultipleOrders(obj);
    } else {
      this.errorMessage = 'No Changes Have Been Made To Any Of The Orders';
      setTimeout(() => {
        this.errorMessage = undefined;
      }, 5000);
    }
  }

  hasChanges(
    array1: TrialOrderDetails[],
    array2: TrialOrderDetails[]
  ): boolean {
    for (let i = 0; i < array1.length; i++) {
      const obj1 = array1[i];
      const obj2 = array2[i];

      if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
        return true;
      }
    }

    return false;
  }

  saveMultipleOrders(obj: TrialOrderDetails) {
    const payloadObj: any = this.constructpayload(obj);

    for (let key of Object.keys(payloadObj)) {
      if (payloadObj[key]?.endsWith('#')) {
        payloadObj[key] = payloadObj[key].slice(0, -1);
      }
    }
    payloadObj['specialistCode'] = Array.from(
      { length: obj.orderNumber.split('#').length - 1 },
      () => 'defVal'
    ).join('#');
    this.ngxUiLoaderService.start('Loader-01');
    this.trialOrderService.multipleSaveOrdersForSupplier(payloadObj).subscribe({
      next: () => {
        this.ngxUiLoaderService.stop('Loader-01');
        this.getGridData();
        this.successMessage = 'Orders Have Successfully Updated.';
        setTimeout(() => {
          this.successMessage = undefined;
        }, 5000);
      },
      error: () => {
        this.ngxUiLoaderService.stop('Loader-01');
        this.errorMessage =
          'Something Went Wrong. Please Contact System Administrator.';
        setTimeout(() => {
          this.errorMessage = undefined;
        }, 5000);
      },
    });
  }

  constructpayload(obj: TrialOrderDetails) {
    console.log(obj);
    return {
      trialEventId: this.eventId,
      appendOrderNumber: obj.orderNumber,
      appendComment: obj.namcComment,
      appendPOTrackingNumber: obj.poTrackingNumber,
      psmsToRouting: obj.psmsRouting,
      appendDeliveryDate: obj.orderDueDate,
      appendTotalQty: obj.totalQty,
      appendStore: obj.storeAddress,
      appendLineSide: obj.lineSideAddress,
      appendEciNumber: obj.eciNumber,
      appendEciRevision: obj.eciRevision,
      appendTrailerNumber: obj.trailerNumber,
      appendShippingCarrier: obj.shippingCarrier,
      appendTrackingAirbillNumber: obj.trackingAirbillNumber,
      appendShippingDateTime: obj.shippingDateTime,
      appendKanbanNumber: obj.kanbanNumber,
      appendPartName: obj.partName,
      appendLifeCycleCode: obj.lifeCycleCode,
      appendPromiseDate: obj.promiseDate,
      appendOWKLabelLine1: obj.owkLabelLine1,
      appendOWKLabelLine2: obj.owkLabelLine2,
      appendPartNumber: obj.partNumber,
      appendSupplierName: obj.supplierName,
      appendSupplierCode: obj.supplierCd,
      appendSupplierPickupDate: obj.supplierPickupDate,
      supplierComment: obj.supplierComment,
      specialistName: obj.specialistName,
      appendPalletCode: obj.palletCode,
      specialistCode: obj.specialistCd,
      poLineNo: obj.poLineNo,
    };
  }

  retainChangedObjects(
    array1: TrialOrderDetails[],
    array2: TrialOrderDetails[]
  ) {
    array1 = array1.filter((obj1) => {
      const obj2 = array2.find((obj) => obj.orderNumber === obj1.orderNumber);
      return obj2 && JSON.stringify(obj1) !== JSON.stringify(obj2);
    });

    array2 = array2.filter((obj2) => {
      const obj1 = array1.find((obj) => obj.orderNumber === obj2.orderNumber);
      return obj1 && JSON.stringify(obj1) !== JSON.stringify(obj2);
    });

    return [array1, array2];
  }

  compareOrders(
    array1: TrialOrderDetails[],
    array2: TrialOrderDetails[]
  ): TrialOrderDetails {
    let result: TrialOrderDetails = {
      orderNumber: '',
      psmsRouting: '',
      specialistName: '',
      status: '',
      supplierCd: '',
      namcComment: '',
      poTrackingNumber: '',
      orderDueDate: '',
      totalQty: '',
      storeAddress: '',
      lineSideAddress: '',
      eciNumber: '',
      eciRevision: '',
      trailerNumber: '',
      shippingCarrier: '',
      trackingAirbillNumber: '',
      shippingDateTime: '',
      kanbanNumber: '',
      partName: '',
      lifeCycleCode: '',
      promiseDate: '',
      owkLabelLine1: '',
      owkLabelLine2: '',
      partNumber: '',
      supplierName: '',
      supplierComment: '',
      supplierPickupDate: '',
      poLineNo: '',
      naPipeStatus: '',
      palletCode: '',
      
    } as TrialOrderDetails;

    [array1, array2] = this.retainChangedObjects(array1, array2);

    array1.forEach((oldOrder, index) => {
      const newOrder = array2[index];
      result.orderNumber += `${oldOrder.orderNumber}#`;
      result.namcComment +=
        oldOrder.namcComment === newOrder.namcComment
          ? 'defVal#'
          : `${newOrder.namcComment}#`;
      result.poTrackingNumber +=
        oldOrder.poTrackingNumber === newOrder.poTrackingNumber
          ? 'defVal#'
          : `${newOrder.poTrackingNumber}#`;
      result.psmsRouting +=
        oldOrder.psmsRouting === newOrder.psmsRouting
          ? 'defVal#'
          : `${newOrder.psmsRouting}#`;
      result.orderDueDate +=
        oldOrder.orderDueDate === newOrder.orderDueDate
          ? 'defVal#'
          : `${newOrder.orderDueDate}#`; //delivery duew date
      result.totalQty +=
        oldOrder.totalQty === newOrder.totalQty
          ? 'defVal#'
          : `${newOrder.totalQty}#`;
      result.storeAddress +=
        oldOrder.storeAddress === newOrder.storeAddress
          ? 'defVal#'
          : `${newOrder.storeAddress}#`;
      result.lineSideAddress +=
        oldOrder.lineSideAddress === newOrder.lineSideAddress
          ? 'defVal#'
          : `${newOrder.lineSideAddress}#`;
      result.eciNumber +=
        oldOrder.eciNumber === newOrder.eciNumber
          ? 'defVal#'
          : `${newOrder.eciNumber}#`;
      result.eciRevision +=
        oldOrder.eciRevision === newOrder.eciRevision
          ? 'defVal#'
          : `${newOrder.eciRevision}#`;
      result.trailerNumber +=
        oldOrder.trailerNumber === newOrder.trailerNumber
          ? 'defVal#'
          : `${newOrder.trailerNumber}#`;
      result.shippingCarrier +=
        oldOrder.shippingCarrier === newOrder.shippingCarrier
          ? 'defVal#'
          : `${newOrder.shippingCarrier}#`;
      result.trackingAirbillNumber +=
        oldOrder.trackingAirbillNumber === newOrder.trackingAirbillNumber
          ? 'defVal#'
          : `${newOrder.trackingAirbillNumber}#`;
      result.shippingDateTime +=
        oldOrder.shippingDateTime === newOrder.shippingDateTime
          ? 'defVal#'
          : `${newOrder.shippingDateTime}#`;
      result.kanbanNumber +=
        oldOrder.kanbanNumber === newOrder.kanbanNumber
          ? 'defVal#'
          : `${newOrder.kanbanNumber}#`;
      result.partName +=
        oldOrder.partName === newOrder.partName
          ? 'defVal#'
          : `${newOrder.partName}#`;
      result.lifeCycleCode +=
        oldOrder.lifeCycleCode === newOrder.lifeCycleCode
          ? 'defVal#'
          : `${newOrder.lifeCycleCode}#`;
      result.promiseDate +=
        oldOrder.promiseDate === newOrder.promiseDate
          ? 'defVal#'
          : `${newOrder.promiseDate}#`;
      result.owkLabelLine1 +=
        oldOrder.owkLabelLine1 === newOrder.owkLabelLine1
          ? 'defVal#'
          : `${newOrder.owkLabelLine1}#`;
      result.owkLabelLine2 +=
        oldOrder.owkLabelLine2 === newOrder.owkLabelLine2
          ? 'defVal#'
          : `${newOrder.owkLabelLine2}#`;
      result.partNumber +=
        oldOrder.partNumber === newOrder.partNumber
          ? 'defVal#'
          : `${newOrder.partNumber}#`;
      result.supplierName +=
        oldOrder.supplierName === newOrder.supplierName
          ? 'defVal#'
          : `${newOrder.supplierName}#`;
      result.supplierCd +=
        oldOrder.supplierCd === newOrder.supplierCd
          ? 'defVal#'
          : `${newOrder.supplierCd}#`;
      result.supplierPickupDate +=
        oldOrder.supplierPickupDate === newOrder.supplierPickupDate
          ? 'defVal#'
          : `${newOrder.supplierPickupDate}#`;
      result.supplierComment +=
        oldOrder.supplierComment === newOrder.supplierComment
          ? 'defVal#'
          : `${newOrder.supplierComment}#`;
      result.specialistName +=
        oldOrder.specialistName === newOrder.specialistName
          ? 'defVal#'
          : `${newOrder.specialistName}#`;
      //specialist code
      result.poLineNo +=
        oldOrder.poLineNo === newOrder.poLineNo
          ? 'defVal#'
          : `${newOrder.poLineNo}#`;
      result.naPipeStatus +=
        oldOrder.naPipeStatus === newOrder.naPipeStatus
          ? 'defVal#'
          : `${newOrder.naPipeStatus}#`;
      result.palletCode +=
        oldOrder.palletCode === newOrder.palletCode
          ? 'defVal#'
          : `${newOrder.palletCode}#`;
    });

    return result;
  }
}
