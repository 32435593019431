<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<app-supplier-trial-order-buttons
  [eventId]="eventId"
  [currentRole]="currentRole"
  [eventDetailsFlags]="eventDetailsFlags"
  [selectedOrder]="selectedOrder"
  (supplierCommentEmit)="onSupplierCommentEmit($event)"
  [rowCount]="rowCount"
></app-supplier-trial-order-buttons>
<app-grid
  [columnDefs]="columnDefs"
  [displayRowData]="displayRowData"
  [rowSelection]="rowSelection"
  (pageLimit)="onPaginationChanged($event)"
  (select)="onSelectOrder($event)"
></app-grid>
<div class="create_footer fixed-position">
  <button
    class="cancel me-3 mt-2 roboto-bold cancelbtnalign"
    (click)="cancel()"
  >
    BACK
  </button>
  <button
    class="black-button blackbtnalign me-3 mt-2"
    *ngIf="
      eventDetailsFlags.isAllEvents === 'false' &&
      eventDetailsFlags.isEventDeleted === 'false'
    "
    (click) = "onSave()"
  >
    SAVE
  </button>
</div>
