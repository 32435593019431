import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridModule } from 'ag-grid-angular';
import { NgChartsModule } from 'ng2-charts';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BreadcrumbComponent } from './core/breadcrumb/breadcrumb.component';
import { DisableSpecialCharactersDirective } from './core/common/disable-special-characters';
import { ngxUiLoaderConfig } from './core/constant/spinner.constant';
import { DeleteAttributeComponent } from './core/dialogs/delete-attribute/delete-attribute.component';
import { DeleteEventComponent } from './core/dialogs/delete-event/delete-event.component';
import { CancelTrailsComponent } from './core/dialogs/cancel-trails/cancel-trails.component';
import { NamcCommentsActionComponent } from './core/dialogs/namc-comments-action/namc-comments-action.component';
import { SupplierCommentsActionComponent } from './core/dialogs/supplier-comments-action/supplier-comments-action.component';
import { AcceptOrdersDialogComponent } from './core/dialogs/event-and-orders/accept-orders-dialog/accept-orders-dialog.component';
import { CancelWarningComponent } from './core/dialogs/event-and-orders/cancel-warning/cancel-warning.component';
import { ConfirmSelectedOrdersComponent } from './core/dialogs/event-and-orders/confirm-selected-orders/confirm-selected-orders.component';
import { AcceptRejectDialogComponent } from './core/dialogs/event-and-orders/review-namc/review-namc.component';
import { ShipChangeRequestComponent } from './core/dialogs/event-and-orders/ship-change-request/ship-change-request.component';
import { ShipDateNamcComponent } from './core/dialogs/event-and-orders/ship-date-namc/ship-date-namc.component';
import { SupplierMailComponent } from './core/dialogs/event-and-orders/supplier-mail/supplier-mail.component';
import { SupplierShipDateComponent } from './core/dialogs/event-and-orders/supplier-ship-date/supplier-ship-date.component';
import { SendSupplierNotificationComponent } from './core/dialogs/send-supplier-notification/send-supplier-notification.component';
import { WarningPopupComponent } from './core/dialogs/warning-popup/warning-popup.component';
import { FooterComponent } from './core/footer/footer.component';
import { EditConfigurationComponent } from './core/grid-renderer/edit-configuration/edit-configuration.component';
import { EditUserRoleRenderer } from './core/grid-renderer/edit-user-role-renderer.component';
import { ManageAttributeAction } from './core/grid-renderer/manage-attribute-action/manage-attribute-action.component';
import { EditSingleOrderComponent } from './core/grid-renderer/refresh/edit-single-order/edit-single-order.component';
import { RefreshComponent } from './core/grid-renderer/refresh/refresh.component';
import { TrialEventsAction } from './core/grid-renderer/trial-events-action/trial-events-action.component';
import { UserBoxRenderer } from './core/grid-renderer/userboxrenderer.component';
import { ViewEventComponent } from './core/grid-renderer/view-event/view-event.component';
import { GridComponent } from './core/grid/grid.component';
import { NotificationMessageComponent } from './core/notification-message/notification-message.component';
import { ToolbarComponent } from './core/toolbar/toolbar.component';
import { NewMessageComponent } from './pages/home/new-message/new-message.component';
import { ArchivedOrdersComponent } from './pages/events-and-orders/archived-orders/archived-orders.component';
import { EventsAndOrdersComponent } from './pages/events-and-orders/events-and-orders.component';
import { CreateEventComponent } from './pages/events-and-orders/manage-trial-events/create-event/create-event.component';
import { ManageTrialEventsComponent } from './pages/events-and-orders/manage-trial-events/manage-trial-events.component';
import { DisplayHistoryComponent } from './pages/events-and-orders/trial-orders/display-history/display-history.component';
import { DisplayOrderShippingAddressComponent } from './pages/events-and-orders/trial-orders/display-order-shipping-address/display-order-shipping-address.component';
import { NamcCommentsComponent } from './pages/events-and-orders/trial-orders/namc-comments/namc-comments.component';
import { SupplierCommentsComponent } from './pages/events-and-orders/trial-orders/supplier-comments/supplier-comments.component';
import { AppendTrailsComponent } from './pages/events-and-orders/trial-orders/display-trial-orders/append-trails/append-trails.component';
import { DisplayTrialOrdersComponent } from './pages/events-and-orders/trial-orders/display-trial-orders/display-trial-orders.component';
import { NamcTrialOrderButtonsComponent } from './pages/events-and-orders/trial-orders/display-trial-orders/namc-trial-order-buttons/namc-trial-order-buttons.component';
import { ReviewShipDateComponent } from './pages/events-and-orders/trial-orders/display-trial-orders/review-ship-date/review-ship-date.component';
import { ViewEditComponent } from './pages/events-and-orders/trial-orders/display-trial-orders/view-edit/view-edit.component';
import { SupplierTrialOrderButtonsComponent } from './pages/events-and-orders/trial-orders/trial-orders-supplier/supplier-trial-order-buttons/supplier-trial-order-buttons.component';
import { TrialOrdersSupplierComponent } from './pages/events-and-orders/trial-orders/trial-orders-supplier/trial-orders-supplier.component';
import { TrialOrdersComponent } from './pages/events-and-orders/trial-orders/trial-orders.component';
import { UploadOrdersDataComponent } from './pages/events-and-orders/upload-orders-data/upload-orders-data.component';
import { UploadOrdersNamcComponent } from './pages/events-and-orders/upload-orders-namc/upload-orders-namc.component';
import { HomeComponent } from './pages/home/home.component';
import { NoAccessComponent } from './pages/no-access/no-access.component';
import { RoleSelectorComponent } from './pages/role-selector/role-selector.component';
import { ContactsComponent } from './pages/supplier/contacts/contacts.component';
import { MassEmailComponent } from './pages/supplier/mass-email/mass-email.component';
import { OnlineTrainingNamcDocumentsComponent } from './pages/supplier/online-training-namc-documents/online-training-namc-documents.component';
import { LateOrderGridComponent } from './pages/supplier/performance/late-order-grid/late-order-grid.component';
import { PerformanceComponent } from './pages/supplier/performance/performance.component';
import { SupplierComponent } from './pages/supplier/supplier.component';
import { AddAttributeComponent } from './pages/tmna-namc/manage-attributes/add-attribute/add-attribute.component';
import { ManageAttributesComponent } from './pages/tmna-namc/manage-attributes/manage-attributes.component';
import { ModifyAttributeComponent } from './pages/tmna-namc/manage-attributes/modify-attribute/modify-attribute.component';
import { ManageConfigurationsComponent } from './pages/tmna-namc/manage-configurations/manage-configurations.component';
import { ModifyConfigurationComponent } from './pages/tmna-namc/manage-configurations/modify-configuration/modify-configuration.component';
import { AddOnlineTrainingComponent } from './pages/tmna-namc/manage-online-training-namc-documents/add-online-training/add-online-training.component';
import { DeleteDocumentComponent } from './pages/tmna-namc/manage-online-training-namc-documents/delete-document/delete-document.component';
import { ManageOnlineTrainingComponent } from './pages/tmna-namc/manage-online-training-namc-documents/manage-online-training/manage-online-training.component';
import { ReorderOnlineTrainingComponent } from './pages/tmna-namc/manage-online-training-namc-documents/reorder-online-training/reorder-online-training.component';
import { AddEditUserComponent } from './pages/tmna-namc/manage-users/add-edit-user/add-edit-user.component';
import { ManageUsersComponent } from './pages/tmna-namc/manage-users/manage-users.component';
import { TmnaNamcComponent } from './pages/tmna-namc/tmna-namc.component';
import { ConfirmSingleOrderComponent } from './pages/events-and-orders/trial-orders/trial-orders-supplier/confirm-single-order/confirm-single-order.component';

import { CreateTrailsComponent } from './pages/events-and-orders/trial-orders/display-trial-orders/create-trails/create-trails.component';
import { SapInterfaceComponent } from './core/dialogs/sap-interface/sap-interface.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    BreadcrumbComponent,
    ToolbarComponent,
    NotificationMessageComponent,
    RoleSelectorComponent,
    HomeComponent,
    EventsAndOrdersComponent,
    ManageTrialEventsComponent,
    NoAccessComponent,
    MassEmailComponent,
    GridComponent,
    RefreshComponent,
    SendSupplierNotificationComponent,
    SupplierComponent,
    ContactsComponent,
    ManageOnlineTrainingComponent,
    AddOnlineTrainingComponent,
    ReorderOnlineTrainingComponent,
    DeleteDocumentComponent,
    TmnaNamcComponent,
    ManageAttributesComponent,
    ModifyAttributeComponent,
    AddAttributeComponent,
    ManageConfigurationsComponent,
    ModifyConfigurationComponent,
    CreateEventComponent,
    TrialEventsAction,
    ViewEventComponent,
    DeleteEventComponent,
    ManageAttributeAction,
    EditConfigurationComponent,
    DeleteAttributeComponent,
    UserBoxRenderer,
    EditUserRoleRenderer,
    ManageUsersComponent,
    AddEditUserComponent,
    NewMessageComponent,

    UploadOrdersDataComponent,
    ArchivedOrdersComponent,
    UploadOrdersNamcComponent,
    DisplayHistoryComponent,
    TrialOrdersComponent,
    ConfirmSelectedOrdersComponent,
    SupplierShipDateComponent,
    ShipChangeRequestComponent,
    DisplayOrderShippingAddressComponent,
    NamcCommentsComponent,
    NamcCommentsActionComponent,
    SupplierCommentsComponent,
    SupplierCommentsActionComponent,
    PerformanceComponent,
    LateOrderGridComponent,
    DisableSpecialCharactersDirective,
    WarningPopupComponent,
    DisplayTrialOrdersComponent,
    EditSingleOrderComponent,
    TrialOrdersSupplierComponent,
    NamcTrialOrderButtonsComponent,
    SupplierTrialOrderButtonsComponent,
    AcceptOrdersDialogComponent,
    ConfirmSelectedOrdersComponent,
    ConfirmSingleOrderComponent,
    NamcCommentsActionComponent,
    SupplierCommentsActionComponent,
    AppendTrailsComponent,
    CancelTrailsComponent,
    ViewEditComponent,
    CreateTrailsComponent,
    OnlineTrainingNamcDocumentsComponent,
    ReviewShipDateComponent,
    SupplierMailComponent,
    ShipDateNamcComponent,
    AcceptRejectDialogComponent,
    AcceptOrdersDialogComponent,
    CancelWarningComponent,
    SapInterfaceComponent
  ],

  imports: [
    MultiSelectModule,
    DropdownModule,
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    AgGridModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatExpansionModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    MatSelectModule,
    NgxMaterialTimepickerModule,
    MatSlideToggleModule,
    NgChartsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MatRadioModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // Constants,
    DatePipe,
    // ConstantsService
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
